import React, { useEffect, useState } from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import './Button.css';

interface ButtonProps {
  text: string;
}

const Button: React.FC<ButtonProps> = ({ text }) => {
  const [link, setLink] = useState<string>('');

  useEffect(() => {
    const filePath = '/configSistema.txt';

    fetch(filePath)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch link. Status: ${response.status}`);
        }
        return response.text();
      })
      .then(data => {
        setLink(data.trim());
      })
      .catch(error => {
        console.error('Error fetching link:', error);
      });
  }, []);

  return (
    <div className="button-container">
      <a className="button" href={link || '#'} target="_blank" rel="noopener noreferrer">
        <FaTelegramPlane className="button-icon" />
        {text}
      </a>
    </div>
  );
};

export default Button;
