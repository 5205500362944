import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <span>&copy; 2024 André Japa</span>
        <div className="footer-links">
          <a href="/termos-condicoes" className="footer-link">Termos & Condições</a>
          <a href="/politica-privacidade" className="footer-link">Política de Privacidade</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
