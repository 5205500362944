import React from 'react';
import { motion } from 'framer-motion';
import './Image.css';

interface ImageProps {
  src: string;
  alt: string;
}

const Image: React.FC<ImageProps> = ({ src, alt }) => {
  return (
    <motion.div className="image-container" initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }}>
      <img src={src} alt={alt} className="logo" />
    </motion.div>
  );
};

export default Image;