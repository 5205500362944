import React from 'react';
import Image from './Image';
import Heading from './Heading';
import Text from './Text';
import Button from './Button';
import './ContainerIntroducao.css';
import ImagemRainha from './Img/Logo-Rainha.png';

const Container: React.FC = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <div className="content">
        {/* <div className="logo-placeholder" /> */}
          <Image src={ImagemRainha} alt="Logo Rainha" />
          <Heading text="Participe ao vivo de operações sem custo e tenha a oportunidade de ganhar entre R$ 1.000 e R$ 5.000 em apenas uma semana." />
          <Text content="Participe das minhas sessões ao vivo, replicando minhas estratégias três vezes ao dia durante uma semana e potencialize seus resultados financeiros." />
          <Button text="PARTICIPE VOCÊ TAMBÉM!" />
        </div>
      </div>
    </div>
  );
};

export default Container;
