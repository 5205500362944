import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './Text.css';

interface TextProps {
  content: string;
  imageUrl?: string;
}

const Text: React.FC<TextProps> = ({ content, imageUrl }) => {
  const [inView, setInView] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <motion.div
      ref={ref}
      className="text-container"
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -100 }}
      transition={{ duration: 1 }} // Duração da animação em segundos
    >
      <div className="text-content">
        {imageUrl && (
          <motion.img
            src={imageUrl}
            alt="Optional visual"
            className="text-image"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }}
            transition={{ duration: 2, delay: 0.5 }} // Duração da animação da imagem com delay
          />
        )}
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
          transition={{ duration: 2, delay: 0.2 }} // Duração da animação do texto com delay
        >
          {content}
        </motion.p>
      </div>
    </motion.div>
  );
};

export default Text;
