import React from 'react';
import ContainerIntroducao from './components/ContainerIntroducao';
import ContainerAlvos from './components/ContainerAlvos';
import ContainerFinal from './components/ContainerFinal';
import './App.css';
import Footer from './components/Footer';

const App: React.FC = () => {
  return (
    <div className='backGround'>
      <ContainerIntroducao />
      <ContainerAlvos />
      <ContainerFinal />
      <Footer />
    </div>
  );
};

export default App;