import React from 'react';
import Button from './Button';
import './ContainerAlvos.css';
import Heading from './Heading';
import Text from './Text';
import iconYesPreto from './Img/icon-Yes.png';

const ContainerAlvos: React.FC = () => {

  return (
    <div className="containerAlvo">
      <div className="inner-containerAlvo">
        <div className="heading-containerAlvo">
          <h2>Nosso público-alvo é composto por quais tipos de pessoas?</h2>
        </div>
        <div className='conteudo-ConteinerAlvo'>
          <div>
            <Heading text="Para Novatos e Veteranos no Mercado Financeiro:" />
            <Text content="Se você deseja expandir suas habilidades e aprofundar seu conhecimento, participe das nossas sessões ao vivo, onde revelamos estratégias avançadas e fornecemos insights detalhados sobre o mercado." imageUrl={iconYesPreto}></Text>
            <Text content="Se você está dando os primeiros passos no mercado financeiro, nós fornecemos a orientação essencial para que você comece com segurança e confiança." imageUrl={iconYesPreto}></Text>
          </div>
          <div>
            <Heading text="Para quem buscam segurança e estabilidade financeira:" />
            <Text content="Se você está desempregado ou preocupado com a possibilidade de perder seu emprego, descubra como criar uma renda estável e segura através de estratégias financeiras inteligentes." imageUrl={iconYesPreto}></Text>
            <Text content="Se você busca uma fonte adicional de renda, descubra como aumentar seus ganhos através de operações que se encaixam no seu tempo livre." imageUrl={iconYesPreto}></Text>
          </div>
        </div>
        <div className='conteudo-ConteinerAlvo'>          
          <div>
            <Heading text="Para Aqueles que Buscam Superar Desafios Pessoais:" />
            <Text content="Se você enfrenta desafios relacionados à ansiedade ou ao controle emocional, nosso grupo está aqui para ajudar você a construir a mentalidade e a disciplina essenciais para alcançar sucesso nas operações." imageUrl={iconYesPreto}></Text>
            <Text content="Se você enfrenta dívidas ou possui grandes objetivos financeiros, oferecemos orientações práticas para conquistar a liberdade financeira e proporcionar apoio à sua família." imageUrl={iconYesPreto}></Text>
          </div>
          <div>
            <Heading text="Para Aqueles que Desejam Maximizar Seus Lucros:" />
            <Text content="Se você ainda não obteve ganhos substanciais no mercado financeiro ou almeja alcançar lucros superiores a R$10.000, nosso método testado e comprovado pode ajudá-lo a atingir esses objetivos com sucesso." imageUrl={iconYesPreto}></Text>
          </div>
        </div>
        <Button text="PARTICIPE VOCÊ TAMBÉM!" />
      </div>
    </div>
  );
};

export default ContainerAlvos;
