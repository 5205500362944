import React from 'react';
import './Heading.css';

interface HeadingProps {
  text: string;
}

const Heading: React.FC<HeadingProps> = ({ text }) => {
  return (
    <div className="heading-container">
      <h2>{text}</h2>
    </div>
  );
};

export default Heading;