import React from 'react';
import './ContainerFinal.css';
import Heading from './Heading';
import Text from './Text';
import iconNo from './Img/No-Red.png'
import iconYes from './Img/Yes-Verde.png';
import iconLike from './Img/LIKEGREEN.png';
import iconDeslike from './Img/DESLIKERED.png';

interface HeadingWithImageProps {
  text: string;
  imageUrl: string;
}

const HeadingWithImage: React.FC<HeadingWithImageProps> = ({ text, imageUrl }) => {
  return (
    <div className="heading-with-image">
      <img src={imageUrl} alt="Icon" className="heading-image" />
      <Heading text={text} />
    </div>
  );
};

const ContainerFinal: React.FC = () => {
  return (
    <div className="containerFinal">
      <div className="inner-containerFinal">
        <div className="heading-containerFinal">
          <h2>Estratégias de Investimento!</h2>
        </div>
        <div className='conteudo-ConteinerFinal'>
          <div className='conteudoEstiloVerde'>
            <HeadingWithImage text="Siga esse caminho:" imageUrl={iconYes} />
            <Text content="Abordagens metódicas e baseadas em uma análise minuciosa." imageUrl={iconLike}/>
            <Text content="Diversificar investimentos para reduzir riscos." imageUrl={iconLike} />
            <Text content="Educação constante e adaptações periódicas conforme as demandas do mercado." imageUrl={iconLike}/>
          </div>
          <div className='conteudoEstiloVermelho'>
            <HeadingWithImage text="Evite esse caminho:" imageUrl={iconNo} />
            <Text content="Escolhas guiadas por emoções sem fundamentação analítica." imageUrl={iconDeslike} />
            <Text content="Deficiência na diversificação e na formação especializada." imageUrl={iconDeslike}/>
            <Text content="Conforto excessivo após alcançar pequenas vitórias." imageUrl={iconDeslike}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerFinal;
